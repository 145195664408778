import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import new_quotation from "../views/new_quotation";
import dashboard from "../views/dashboard";
import search from "../views/search";
import addItems from "../views/AddItems";
import confirmQuotation from "@/views/confirmQuotation";
import modify from "@/views/modify";
import listItems from "@/views/listItems";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: "/create",
    name: "New Quotation",
    component: new_quotation,
    props: true
  },
  {
    path: "/dash",
    name: "Dashboard",
    component: dashboard
  },
  {
    path: "/search",
    name: "Search Past Client",
    component: search
  },
  {
    path: '/add',
    name: 'New Items',
    component: addItems
  },
  {
    path: '/confirm',
    name: 'Confirm Quotation',
    component:  confirmQuotation
  },
  {
    path: '/modify',
    name: "Modify Items",
    component: modify
  },
  {
    path: "/list",
    name: "List Items",
    component: listItems
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const DEFAULT_TITLE = 'Aera Electricals';
router.afterEach((to) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.name || DEFAULT_TITLE;
  });
});

export default router
