<template>
  <v-app>
    <v-card elevation="2" style="padding: 10px;">
      <v-data-table
          :headers="headers"
          :items="main_data"
          :options.sync="options"
          :loading="loading"
          class="elevation-1"
      >
        <template #item.Id="{ value }">
        <span v-on:click="previewQuotation(value)" style="color: #1D80FB;text-decoration: underline;">
          {{ value }}
        </span>
        </template>
      </v-data-table>
    </v-card>

    <IdSearch :id="preview_id" ref="IdSearch" :class="idFlag"></IdSearch>

    <v-snackbar
        v-model="snackbarCheck"
        :timeout="2000"
        color="error"
        text
    >
      {{ errorText }}
    </v-snackbar>
  </v-app>
</template>

<script>
import axios from "axios";
import previewSearchId from "@/components/previewSearchId";

export default {
  name: "previewSearchByClient",
  components: {
    IdSearch: previewSearchId
  },
  data() {
    return {
      raw_data: {},
      main_data: [],
      loading: false,
      lastKey: null,
      options: {},
      snackbarCheck: false,
      errorText: null,
      statusCode: null,
      preview_id: null,
      idFlag: "ID_hidden",
      headers: [
        {
          text: 'Quotation Id',
          align: 'start',
          sortable: false,
          value: 'Id',
        },
        {text: 'Date', value: 'Timestamp'},
        {text: 'Care Of', value: 'careOf'},
        {text: 'Client Name', value: 'clientName'},
        {text: 'Total Price', value: 'Total'},
        {text: 'Created By', value: 'CreatedBy'},
        {text: 'Status', value: 'Status'},
      ],
    }
  },
  watch: {},
  methods: {
    async getDataFromApi() {
      this.main_data = [];
      this.loading = true
      await this.ApiCall();
      this.loading = false;
    },
    async previewQuotation(value) {
      await this.$refs.IdSearch.fetchCaller(value);
      this.idFlag = "ID_visible";
    },
    async ApiCall() {
      const {page, itemsPerPage} = this.options
      console.log(page);
      let statusShort = this.status === "Confirmed" ? "C" : "NC";
      let data = {
        'pageSize': itemsPerPage,
        'status': statusShort,
        'name': this.name.trim(),
        'LastEvaluatedKey': this.lastKey
      }
      let typeShort = this.type === "Client" ? "SearchByClient" : "SearchByCare";
      const options = {
        url: 'https://aera-api.amolbohora.com/view_quotation',
        method: 'POST',
        headers: {
          'content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('Token')
        },
        data: JSON.stringify({
          'type': typeShort,
          'data': data
        })
      };
      let that = this;
      axios(options)
          .then(async response => {
            if (response.status === 200) {
              for (var i in response.data['data']) {
                let temp = response.data['data'][i];
                let old_date = new Date(parseInt(temp['Timestamp']) * 1000);
                const dd = String(old_date.getDate()).padStart(2, '0');
                const mm = String(old_date.getMonth() + 1).padStart(2, '0'); //January is 0!
                const yyyy = old_date.getFullYear();
                temp['Timestamp'] = dd + '/' + mm + '/' + yyyy;
                temp['Status'] = this.status;
                that.main_data.push(temp);
              }
            }
          })
          .catch(function (error) {
            that.errorText = error.response.data.details;
            that.snackbarCheck = true;
            that.statusCode = error.status;
            if (error.response.status === 401){
              localStorage.removeItem('Token');
              that.$router.replace("/");
            }
          });
    },
  },
  props: [
    'status', 'name', 'type'
  ]
}
</script>

<style scoped>
.ID_hidden {
  display: none;
}

.ID_visible {
  display: block;
}

</style>