<template>
  <v-app>
    <v-container fluid fill-height v-if="!viewId">
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
          <v-card class="elevation-12">
            <v-toolbar dark color="primary">
              <v-toolbar-title>Search By Client or Status</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-form ref="SBS">
                <v-select
                    v-model="status"
                    :items="['Not Confirmed', 'Confirmed']"
                    label="Status"
                    type="text"
                    :rules="rules.status"
                ></v-select>
                <v-select
                    v-model="type"
                    :items="['Client', 'Care Of']"
                    label="Client or Care Of"
                    type="text"
                    :rules="rules.type"
                ></v-select>
                <v-autocomplete
                    auto-select-first
                    clearable
                    :items="names"
                    label="Name" outlined :rules="rules.name"
                    v-model="name"
                ></v-autocomplete>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" v-on:click="searchClient">Search</v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <ClientSearch :status="status" :type="type" :name="name" :class="searchFlag" ref="searcher"></ClientSearch>

    <v-container fluid fill-height v-if="!viewPast">
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
          <v-card class="elevation-12">
            <v-toolbar dark color="primary">
              <v-toolbar-title>Open a past quotation</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-form ref="SID">
                <v-text-field
                    v-model="id"
                    label="Id"
                    type="text"
                    :rules="rules.id"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" v-on:click="searchId">Search</v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <IdSearch :id="id" ref="IdSearch" :class="idFlag"></IdSearch>
    <v-snackbar
        v-model="snackbarCheck"
        :timeout="2000"
        color="error"
        text
    >
      {{ errorText }}
    </v-snackbar>
  </v-app>
</template>

<script>
import PreviewSearchClient from "../components/previewSearchClient";
import previewSearchId from "@/components/previewSearchId";
import isJwtTokenExpired from "jwt-check-expiry";

export default {
  name: "past_client",
  components: {
    ClientSearch: PreviewSearchClient,
    IdSearch: previewSearchId
  },
  data() {
    return {
      status: null,
      name: null,
      names: [],
      id: null,
      type: null,
      searchFlag: "SBN_hidden",
      idFlag: "ID_hidden",
      rules: {
        status: [
          value => !!value || 'Required',
        ],
        type: [
          value => !!value || 'Required',
        ],
        id: [
          value => !!value || 'Required',
        ],
        name: [
          value => !!value || 'Required'
        ]
      },
      viewId: false,
      viewPast: false,
      isFormValid: false,
      snackbarCheck: false,
      errorText: null,
      statusCode: null,
    }
  },
  methods: {
    async searchClient() {
      if (this.$refs.SBS.validate()) {
        this.searchFlag = "SBN_visible";
        this.viewPast = true;
        await this.$refs.searcher.getDataFromApi();
      }
    },
    searchId() {
      if (this.$refs.SID.validate()) {
        this.viewId = true;
        this.$refs.IdSearch.fetchData();
        this.idFlag = "ID_visible"
      }
    }
  },
  beforeCreate() {
    if (localStorage.getItem("Token") === null || isJwtTokenExpired(localStorage.getItem("Token"))) {
      localStorage.removeItem('Token');
      this.$router.replace("/");
    }
  },
  async created() {
    var url = ("https://aera-cdn.amolbohora.com/data/client_names.json?jwt=" + localStorage.getItem('Token'));
    fetch(url)
        .then(res => res.json())
        .then(out => {
              for (var i in out['data']) {
                if (!this.names.includes(out['data'][i]))
                  this.names.push(out['data'][i]);
              }
            }
        )

    url = ("https://aera-cdn.amolbohora.com/data/co_names.json?jwt=" + localStorage.getItem('Token'));
    fetch(url)
        .then(res => res.json())
        .then(out => {
              for (var i in out['data']) {
                if (!this.names.includes(out['data'][i]))
                  this.names.push(out['data'][i]);
              }
            }
        )

  }
}
</script>

<style scoped>

.SBN_hidden, .ID_hidden {
  display: none;
}

.SBN_visible, .ID_visible {
  display: block;
}

</style>