<template>
  <v-app>
    <div class="main">
      <div class="input_data rounded-t-xl">
        <v-toolbar>
          Welcome {{ this.userName }}
        </v-toolbar>
        <br>
        <v-form ref="form" lazy-validation v-model="valid">
          <div v-for="(item,index) in form_data.newItemData" :key="index">
            <v-toolbar>
              Item {{ index + 1 }}
            </v-toolbar>
            <br>
            <v-text-field label="Item Name" outlined type="text" :rules="rules.itemName"
                          v-model="item.itemName"></v-text-field>
            <v-text-field label="Item Price" outlined type="number" :rules="rules.itemPrice"
                          v-model="item.itemPrice"></v-text-field>
            <v-file-input
                accept="image/*"
                label="Item Image"
                show-size
                :rules="rules.itemImage"
                v-model="item.itemImage"
            ></v-file-input>
            <br>
            <v-row>
              <v-col class="minusButton">
                <v-btn
                    elevation="18"
                    fab
                    color="primary"
                    rounded
                    small
                    @click="minus(index)"
                >-
                </v-btn>
              </v-col>
              <v-col class="plusButton">
                <v-btn
                    elevation="18"
                    fab
                    color="primary"
                    rounded
                    small
                    @click="plus"
                >+
                </v-btn>
                <br>
              </v-col>
            </v-row>
            <br>
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" type="submit" v-on:click.prevent="sendData" :disabled="!valid">Save & Upload</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-form>
      </div>
    </div>
    <v-snackbar
        v-model="snackbarCheck"
        :timeout="2000"
        color="error"
        text
    >
      {{ errorText }}
    </v-snackbar>
    <v-overlay
        absolute="absolute"
        :value="overlay"
        opacity="0.8"
    >
      <br>
      <v-progress-circular
          :rotate="-90"
          :size="200"
          :width="15"
          :value="uploadProgress"
          color="white"
      >
        {{ uploadText }}
      </v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
import isJwtTokenExpired, {decode} from "jwt-check-expiry";
import axios from "axios";

export default {
  name: "AddItems",
  data() {
    return {
      valid: false,
      userName: null,
      overlay: false,
      uploadProgress: 0,
      uploadText: "",
      errorText: "",
      snackbarCheck: false,
      form_data: {
        newItemData: [{
          itemName: "",
          itemPrice: null,
          itemImage: ''
        }]
      },
      generatedLinks: [],
      rules: {
        itemName: [
          value => !!value || 'Required.',
        ],
        itemPrice: [
          value => !!value || 'Required.',
        ],
        itemImage: [
          value => !!value || 'Required.',
        ]
      }
    }
  },
  methods: {
    plus() {
      this.form_data.newItemData.push({
        itemName: null,
        itemPrice: null,
        itemImage: ''
      })
    },
    minus(index) {
      this.form_data.newItemData.splice(index, 1);
      if (this.form_data.newItemData.length <= 0) {
        this.form_data.data.newItemData[0] = {
          itemName: null,
          itemPrice: null,
          itemImage: ''
        }
      }
    },
    sendData() {
      if (this.$refs.form.validate()) {
        this.overlay = true;
        this.uploadText = "Loading";
        this.uploadProgress = 25;
        const nested_data = {};
        for (let i = 0; i < this.form_data.newItemData.length; i++) {
          nested_data[this.form_data.newItemData[i].itemName.trim()] = this.form_data.newItemData[i].itemPrice;
        }
        this.uploadProgress = 50;
        let data = JSON.stringify({
          "data": {
            "new_items": nested_data
          }
        });
        const options = {
          url: 'https://aera-api.amolbohora.com/add_items',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': 'Bearer ' + localStorage.getItem('Token')
          },
          data: data
        };
        let that = this;
        this.uploadProgress = 75;
        axios(options)
            .then(async response => {
              if (response.status === 200) {
                that.generatedLinks = response.data.generated_links;
                const timer = ms => new Promise(res => setTimeout(res, ms))
                that.uploadProgress = 100;
                await timer(500);
                that.overlay = false;
                that.uploadProgress = 0;
                await that.uploadImage();
                that.$router.go();
              }
            })
            .catch(function (error) {
              that.errorText = error.response['data']['details'];
              that.snackbarCheck = true;
              if (error.response.status === 401){
                localStorage.removeItem('Token');
                that.$router.replace("/");
              }
            });
      }
    },
    async uploadImage() {
      const timer = ms => new Promise(res => setTimeout(res, ms))
      this.overlay = true;
      let length = this.generatedLinks.length;
      this.uploadProgress = 0;
      for (let i = 0; i < length; i++) {
        let fileData = this.form_data.newItemData[i].itemImage;
        let config = {
          method: 'put',
          url: this.generatedLinks[i],
          data: fileData
        }
        let that = this;
        this.uploadText = "Item " + (i + 1) + " Uploading";
        axios(config)
            .then(function () {
              that.uploadProgress += parseFloat(100.0 / length);
            })
            .catch(function (error) {
              that.errorText = error.response['data']['details'];
              that.snackbarCheck = true;
              console.log(error);
            });
        await timer(350);
      }
      await timer(700);
      this.overlay = false;
    },
  },
  beforeCreate() {
    if (localStorage.getItem("Token") === null || isJwtTokenExpired(localStorage.getItem("Token"))) {
      localStorage.removeItem('Token');
      this.$router.replace("/");
    }
  },
  created() {
    let temp = decode(localStorage.getItem("Token"))['payload'];
    this.userName = temp['name']
  }
}
</script>

<style scoped>

.input_data {
  padding-top: 50px;
  padding-left: 100px;
  padding-right: 100px;
}

.plusButton {
  display: flex;
  justify-content: flex-end;
}

.v-progress-circular {
  margin: 1rem;
}


@media only screen and (max-width: 1200px) {
  /*Tablets [601px -> 1200px]*/
}

@media only screen and (max-width: 600px) {
  .input_data {
    padding-right: 50px;
    padding-left: 50px;
  }
}

@media only screen and (max-width: 425px) {
  .input_data {
    padding-left: 25px;
    padding-right: 25px;
  }
}


</style>