<template>
  <v-app id="inspire">
    <v-main>
      <v-img src="../assets/logo.webp" alt="Aera Fans Logo" class="logo"/>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-12">
              <v-toolbar dark color="primary">
                <v-toolbar-title>Login</v-toolbar-title>
              </v-toolbar>
              <v-form ref="form" lazy-validation v-model="valid" v-on:keyup.enter.prevent="login">

                <v-card-text>
                  <v-text-field
                      v-model="emailId"
                      label="Email Id"
                      type="text"
                      :rules="rules.emailId"
                  ></v-text-field>
                  <v-text-field
                      v-model="password"
                      label="Password"
                      type="password"
                      :rules="rules.password"
                  ></v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" type="submit" v-on:click.prevent="login" :disabled="!valid">Login</v-btn>
                </v-card-actions>
              </v-form>
              <v-progress-linear
                  indeterminate
                  color="red"
                  v-if="loadFlag"
              ></v-progress-linear>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
      <v-snackbar
          v-model="snackbarCheck"
          :timeout="2000"
          color="error"
      >
        {{ errorText }}
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import isJwtTokenExpired from "jwt-check-expiry";

export default {
  name: 'Home',
  data() {
    return {
      valid: true,
      emailId: null,
      password: null,
      loadFlag: false,
      errorText: "",
      snackbarCheck: false,
      rules: {
        emailId: [
          value => !!value || 'Required.',
          value => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || 'E-mail must be valid',
        ],
        password: [
          value => !!value || 'Required',
        ]
      },
    }
  },
  methods: {
    async login() {
      this.$refs.form.validate()
      if (this.valid) {
        this.loadFlag = true;
        let data = JSON.stringify({
          "eventType": "login",
          "data": {
            "email": this.emailId,
            "password": this.password
          }
        });
        const options = {
          url: 'https://aera-api.amolbohora.com/login',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: data
        };
        let that = this;
        axios(options)
            .then(response => {
              if (response.status === 200) {
                localStorage.setItem("Token", response.data.Token);
                that.$router.push("/dash");
              }
            })
            .catch(function (error) {
              that.errorText = error.response.data.details;
              that.snackbarCheck = true;
            });
      }
      this.loadFlag = false;
    }
  },
  beforeCreate() {
    if (localStorage.getItem("Token") !== null && !isJwtTokenExpired(localStorage.getItem("Token")))
      this.$router.replace("/dash");
  }
}
</script>

<style scoped>

.logo {
  max-height: 500px;
  max-width: 500px;
  margin: auto;
  display: block;
  margin-bottom: -40vh;
}


</style>