<template>
  <v-app>
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
          <v-card class="elevation-12">
            <v-toolbar dark color="primary">
              <v-toolbar-title>Modify Item Price</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-form ref="Modify">
                <v-autocomplete
                    auto-select-first
                    clearable
                    :items="itemName"
                    label="Make of Item" outlined :rules="rules.name" v-model="name"
                    v-on:input="applyPrice"
                ></v-autocomplete>
                <v-text-field
                    type="number"
                    v-model="currentPrice"
                    label="Current Price"
                    readonly
                    outlined></v-text-field>
                <v-text-field
                    type="number"
                    v-model="price"
                    label="New Price"
                    outlined></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" type="submit" v-on:click="update">Update</v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <v-snackbar
        v-model=  "snackbarCheck"
        :timeout="2000"
        color="error"
        text
    >
      {{ errorText }}
    </v-snackbar>
  </v-app>
</template>

<script>
import isJwtTokenExpired from "jwt-check-expiry";
import axios from "axios";

export default {
  name: "modify",
  data() {
    return {
      name: null,
      price: null,
      snackbarCheck: false,
      errorText: null,
      itemName: [],
      currentPrice: null,
      itemPrice: {},
      rules: {
        name: [
          value => !!value || 'Required.',
        ]
      }
    }
  },

  methods: {
    applyPrice() {
      this.currentPrice = this.itemPrice[this.name];
    },
    update() {
      if (this.$refs.Modify.validate()) {
        const nested_data = {};
        nested_data[this.itemName] = this.itemPrice;
        let data = JSON.stringify({
          "data": {
            "new_items": nested_data
          }
        });
        const options = {
          url: 'https://aera-api.amolbohora.com/modify_items',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': 'Bearer ' + localStorage.getItem('Token')
          },
          data: data
        };
        let that = this;
        axios(options)
            .then(async response => {
              if (response.status === 200) {
                console.log(response.status.data.detials);
              }
            })
            .catch(function (error) {
              that.errorText = error.response.data.details;
              that.snackbarCheck = true;
            });
      }
    },
  },
  beforeCreate() {
    if (localStorage.getItem("Token") === null || isJwtTokenExpired(localStorage.getItem("Token"))) {
      localStorage.removeItem('Token');
      this.$router.replace("/");
    }
  },
  async created() {
    var url = ("https://aera-cdn.amolbohora.com/data/item_data.json?jwt=" + localStorage.getItem("Token"));
    fetch(url)
        .then(res => res.json())
        .then(out => {
              Object.keys(out).forEach(key => {
                this.itemName.push(key);
              })
              this.itemPrice = out;
            }
        )
  }
}
</script>

<style scoped>

</style>