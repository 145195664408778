<template>
  <v-app>
    <div class="main">
      <div class="input_data rounded-t-xl">
        <v-toolbar>
          Quotation for&nbsp;<b>{{ this.form_data.clientName }}</b>
        </v-toolbar>
        <br>
        <v-form ref="form" lazy-validation>
          <v-text-field
            label="Client Name"
            v-model="form_data.clientName"
            outlined
            readonly></v-text-field>
          <v-text-field
              label="Client Address"
              v-model="form_data.clientAddress"
              outlined
              readonly
          >
          </v-text-field>
          <v-text-field
              label="Client Contact"
              v-model="form_data.clientPhone"
              outlined
              readonly
          >
          </v-text-field>
          <v-text-field
              label="Client GSTIN"
              v-model="form_data.clientGSTIN"
              outlined
              readonly
          >
          </v-text-field>
          <v-text-field
              label="Client Company"
              v-model="form_data.clientCompany"
              outlined
              readonly
          >
          </v-text-field>

          <v-text-field
            label="Care Of"
            v-model="form_data.careOf"
            readonly
            outlined
            ></v-text-field>


          <h2 style="text-align: center; background-color: aquamarine;" class="rounded-t-xl">Item Details</h2>
          <br>
          <div v-for="(item, index) in form_data.data" :key="index">
            <v-text-field
              label="Make of Item"
              outlined  v-model="item.name" readonly></v-text-field>
            <v-text-field label="Description" outlined readonly
                          v-model="item.description"></v-text-field>
            <v-text-field label="Quantity" outlined type="number" readonly
                          v-model="item.quantity" ></v-text-field>
            <v-text-field label="Item Price" outlined readonly v-model="item.price"
                          ></v-text-field>
            <v-text-field label="Discount" outlined type="number" v-model="item.discount" readonly
                          ></v-text-field>
            <v-banner
                elevation="20"
                rounded
                style="background-color: antiquewhite"
            >Total Price for {{ item.name }} is ₹ {{ priceWithCommas(item.amount) }} <small>Including Discount</small>
            </v-banner>
            <br>
            <br>
          </div>
          <v-text-field label="Transport Price" outlined readonly type="number"
                        v-model="form_data.transportCharge"></v-text-field>
          <v-text-field label="Installation Price" outlined readonly type="number"
                        v-model="form_data.installationCharge"></v-text-field>
          <v-banner
              elevation="7"
              rounded
              style="background-color: beige"
          >Final Price is ₹ {{ priceWithCommas(this.form_data.Total) }}
          </v-banner>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" v-on:click="reprint">Re-Print</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" v-on:click="editQuotation">Edit</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-form>
      </div>
    </div>
  </v-app>
</template>

<script>
import axios from "axios";

export default {
  name: "previewSearchId",
  data() {
    return {
      form_data: {
        clientName: "",
        clientAddress: "",
        clientPhone: "",
        clientCompany: "",
        clientGSTIN: "",
        careOf: "",
        Id: "",
        data: [{
          name: "",
          description: "",
          quantity: null,
          price: 0.0,
          discount: 0,
          netPrice: 0,
          amount: 0
        }],
        transportCharge: null,
        installationCharge: null,
        Total: 0,
      },
    }
  },
  methods: {
    priceWithCommas(val) {
      if (isNaN(val) || val === null)
        return 0;
      val = String(val);
      var lastThree = val.substring(val.length - 3);
      var otherNumbers = val.substring(0, val.length - 3);
      if (otherNumbers !== '')
        lastThree = ',' + lastThree;
      return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    },
    async fetchCaller(val) {
      /* Needed, as id is not being passed from search Client*/
      this.id = val;
      await this.fetchData()
    },
    fetchData() {
      let data = {
        'quote_id': this.id
      }
      const options = {
        url: 'https://aera-api.amolbohora.com/view_quotation',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Authorization': 'Bearer ' + localStorage.getItem('Token')
        },
        data: JSON.stringify({
          'type': "SID",
          'data': data
        })
      };
      let that = this;
      axios(options)
          .then(async response => {
            if (response.status === 200) {
              const data = response.data;
              this.form_data = data['data'];
            }
          })
          .catch(function (error) {
            that.errorText = error.response.data.details;
            that.snackbarCheck = true;
            that.statusCode = error.status;
            if (error.response.status === 401){
              localStorage.removeItem('Token');
              that.$router.replace("/");
            }
          });
    },
    editQuotation(){
      this.$router.push({
        path: "/create?editData="+JSON.stringify(this.form_data),
      })
    },
    reprint(){
      this.$router.push({
        path: "/create?reprint="+JSON.stringify(this.form_data),
      })
    }
  },
  props: ["id"]
}
</script>

<style scoped>
.input_data {
  padding-top: 50px;
  padding-left: 100px;
  padding-right: 100px;
}

@media only screen and (max-width: 600px) {
  .input_data {
    padding-right: 50px;
    padding-left: 50px;
  }
}

@media only screen and (max-width: 425px) {
  .input_data {
    padding-left: 25px;
    padding-right: 25px;
  }
}

</style>