<template>
  <v-app>
    <v-toolbar>
      Welcome {{ this.userName }}
    </v-toolbar>
    <br>
    <v-simple-table class="item_table">
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-center">
            Item Name , Price
          </th>
          <th class="text-center">
            Image
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="(item, index) in itemPrice" :key="index"
        >
          <td>{{ index }} , ₹&nbsp;{{ item }}</td>
          <td><img
              :src="imageLinkGen(index)"
              loading="lazy"
              style="width: 170px; height: 90px;"/><small>If Blurred it's low resolution, update if possible.</small></td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-app>
</template>

<script>
import isJwtTokenExpired, {decode} from "jwt-check-expiry";

export default {
  name: "listItems",
  data() {
    return {
      userName: null,
      token: null,
      itemPrice: null,
    }
  },
  methods: {
    imageLinkGen(item) {
      return "https://aera-cdn.amolbohora.com/images/" + item + ".jpg?jwt=" + this.token;
    },
  },
  beforeCreate() {
    if (localStorage.getItem("Token") === null || isJwtTokenExpired(localStorage.getItem("Token"))) {
      localStorage.removeItem('Token');
      this.$router.replace("/");
    }
  },
  async created() {
    let temp = decode(localStorage.getItem("Token"))['payload'];
    this.userName = temp['name']
    this.token = localStorage.getItem("Token");
    var url = ("https://aera-cdn.amolbohora.com/data/item_data.json?jwt=" + this.token);
    fetch(url)
        .then(res => res.json())
        .then(out => {
              this.itemPrice = out;
            }
        )
  }
}
</script>

<style scoped>

.item_table{
  padding: 10px;
}

</style>