<template>
  <v-app>
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
          <v-card class="elevation-12">
            <v-toolbar dark color="primary">
              <v-toolbar-title>Confirm a past quotation</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-form ref="SID">
                <v-text-field
                    v-model="id"
                    label="Id"
                    type="text"
                    :rules="rules.id"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" v-on:click="confirm">Confirm</v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <v-snackbar
        v-model="snackbarCheck"
        :timeout="2000"
        color="error"
        text
    >
      {{ errorText }}
    </v-snackbar>
    <v-alert type="success" v-if="successCheck">
      Quotation Confirmed
    </v-alert>
  </v-app>
</template>

<script>
import axios from "axios";

export default {
  name: "confirmQuotation",
  data() {
    return {
      id: null,
      snackbarCheck: false,
      errorText: null,
      statusCode: null,
      successCheck:false,
      rules:{
        id: [
          value => !!value || 'Required',
        ],
      }
    }
  },
  methods:{
    confirm(){
      const options = {
        url: 'https://aera-api.amolbohora.com/confirm_quotation',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Authorization': 'Bearer ' + localStorage.getItem('Token')
        },
        data: JSON.stringify({
          'data': {
            "Id": this.id
          }
        })
      };
      let that = this;
      axios(options)
          .then(async response => {
            if (response.status === 200) {
              this.successCheck = true;
              setTimeout(function(){
                window.location.reload();
              }, 2000);
            }
          })
          .catch(function (error) {
            that.errorText = error.response.data.details;
            that.snackbarCheck = true;
            that.statusCode = error.status;
            if (error.response.status === 401){
              localStorage.removeItem('Token');
              that.$router.replace("/");
            }
          });
    }
  }

}
</script>

<style scoped>

</style>