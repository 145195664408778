<template>
  <v-app>
    <div class="main">
      <div class="input_data rounded-t-xl">
        <v-toolbar>
          Welcome {{ this.userName }}
        </v-toolbar>
        <br>
        <v-toolbar>
          Quotation for&nbsp;<b>{{ this.form_data.clientName }}</b>
        </v-toolbar>
        <br>
        <v-form ref="form" lazy-validation v-model="valid">
          <v-combobox
              auto-select-first
              clearable
              label="Client Name"
              :rules="rules.clientName"
              v-model="form_data.clientName"
              :search-input.sync="clientSearch"
              outlined
              combobox
              :items="clientNames"
          >
            <template v-slot:no-data>
              <v-list-item>
                <span class="subheading">Add a new Client </span>
                <v-chip
                    color="orange"
                    label
                    small
                >
                  {{ clientSearch }}
                </v-chip>
              </v-list-item>
            </template>
          </v-combobox>

          <v-text-field
              label="Client Address"
              :rules="rules.clientName"
              v-model="form_data.clientAddress"
              outlined
          >
          </v-text-field>
          <v-text-field
              label="Client Contact"
              v-model="form_data.clientPhone"
              outlined
          >
          </v-text-field>
          <v-text-field
              label="Client GSTIN"
              v-model="form_data.clientGSTIN"
              outlined
          >
          </v-text-field>
          <v-text-field
              label="Client Company"
              v-model="form_data.clientCompany"
              outlined
          >
          </v-text-field>

          <v-combobox
              auto-select-first
              clearable
              label="Care Of"
              v-model="form_data.careOf"
              :search-input.sync="careOfSearch"
              outlined
              combobox
              :items="careOfNames"
          >
            <template v-slot:no-data>
              <v-list-item>
                <span class="subheading">Add a new Person </span>
                <v-chip
                    color="orange"
                    label
                    small
                >
                  {{ careOfSearch }}
                </v-chip>
              </v-list-item>
            </template>
          </v-combobox>

          <h2 style="text-align: center; background-color: aquamarine;" class="rounded-t-xl">Item Details</h2>
          <br>
          <div v-for="(item, index) in form_data.data" :key="index">
            <v-autocomplete
                auto-select-first
                clearable
                :items="itemName"
                label="Make of Item" outlined :rules="rules.itemName" v-model="item.name"
                v-on:input="priceFetcher(index)"
                v-on:blur="priceFetcher(index)"
            ></v-autocomplete>
            <v-text-field label="Description" outlined :rules="rules.itemDescription"
                          v-model="item.description"></v-text-field>
            <v-text-field label="Quantity" outlined type="number" :rules="rules.itemQuantity"
                          v-model="item.quantity" v-on:input="priceUpdater(index)"></v-text-field>
            <v-text-field label="Item Price" outlined readonly v-model="item.price"
                          v-on:input="priceUpdater(index)"></v-text-field>
            <v-text-field label="Discount" outlined type="number" v-model="item.discount"
                          v-on:input="priceUpdater(index)"></v-text-field>
            <v-banner
                elevation="20"
                rounded
                style="background-color: antiquewhite"
            >Total Price for {{ item.name }} is ₹ {{ priceWithCommas(item.amount) }} <small>Including Discount</small>
            </v-banner>
            <br>
            <v-row>
              <v-col class="minusButton">
                <v-btn
                    elevation="18"
                    fab
                    color="primary"
                    rounded
                    small
                    @click="minus(index)"
                >-
                </v-btn>
              </v-col>
              <v-col class="plusButton">
                <v-btn
                    elevation="18"
                    fab
                    color="primary"
                    rounded
                    small
                    @click="plus"
                >+
                </v-btn>
                <br>
              </v-col>
            </v-row>
            <br>
          </div>
          <v-text-field label="Transport Price" outlined type="number"
                        v-model="form_data.transportCharge"></v-text-field>
          <v-text-field label="Installation Price" outlined type="number"
                        v-model="form_data.installationCharge"></v-text-field>
          <v-banner
              elevation="7"
              rounded
              style="background-color: beige"
          >Final Price is ₹ {{ priceWithCommas(this.total) }}
          </v-banner>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" type="submit" v-on:click.prevent="print" :disabled="!valid">Print and Save</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-form>
        <v-overlay v-if="downloading">
          <v-progress-circular
              :width="3"
              color="red"
              indeterminate
              class="loader">
          </v-progress-circular>
          <br>
          <span style="font-size: larger;">Downloading Images, If some are not downloaded please refresh.</span>
        </v-overlay>
      </div>


      <!--      Print Starts Below-->
      <div class="print-data">
        <div class="header">
          <img src="../assets/bright-logo.webp"
               style="width: 100px; height: 95px; position: absolute; top: -10px;"
               alt="Bright Electric Logo"/>
          <img src="../assets/logo.webp"
               style="width: 200px; height: 150px; position: absolute; right: -25px; top: -40px;"
               alt="Aera Electric Logo"/>
          <h1 style="text-align: center; text-shadow: 5px 5px 0px #F1D4C5;">Quotation</h1>
          <br>
          <h4 style="text-align: center; font-size: 12px;">Shop no.11, Archit Sai Square, Near Suyash Hospital, Mumbai
            Naka, Nashik.</h4>
          <h4 style="text-align: center; font-size: 12px;"> Email: - <a href="mailto:sales@aerafans.com"
                                                                        style=" text-decoration: none;">sales@aerafans.com</a>
            | Mo.No.
            9049016111, 98331 31780 </h4>
          <p style="text-align: left;"><b>To :&emsp;{{ this.form_data.clientName }}</b> <span
              v-if="form_data.clientCompany !== ''">({{ this.form_data.clientCompany }})</span>
            <span v-if="form_data.clientGSTIN !== ''"> GSTIN : ({{ this.form_data.clientGSTIN }})</span>
            &emsp;&emsp;&emsp;<br>&emsp;&emsp;&nbsp;&nbsp;{{ this.form_data.clientAddress }} | <span><b>Contact : </b> {{
                this.form_data.clientPhone
              }}</span>
            <br><b>C/O : {{ this.form_data.careOf }}</b></p>
          <p style=" position:absolute; top: 75px; right: 0px;">Quotation Number :
            {{ this.form_data.Id }}</p>
          <p style=" position:absolute; top: 105px; right: 0px;">{{ date }}</p>
        </div>
        <hr style="height:10px; visibility:hidden;"/>
        <div class="print-table">
          <table>
            <thead>
            <tr>
              <th class="text-center" style="padding: 2px;font-size: small;">Sr. No.</th>
              <th class="text-center" style="padding: 2px;font-size: small;">Image</th>
              <th class="text-center" style="padding: 2px;font-size: small;">Make</th>
              <th class="text-center" style="padding: 2px;font-size: small;">Quantity</th>
              <th class="text-center" style="padding: 2px;font-size: small;">Price</th>
              <th class="text-center" style="padding: 2px;font-size: small;">Special<br>Discount</th>
              <th class="text-center" style="padding: 2px;font-size: small;">Net Price</th>
              <th class="text-center" style="padding: 2px;font-size: small;">Amount</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item,index) in form_data.data" :key="index">
              <td style="margin: -7px">{{ index + 1 }}</td>
              <td><img
                  :src="imageLinkGen(item)"
                  style="width: 170px; height: 90px;"></td>
              <td>
                <span style="overflow-wrap: break-word; margin: -5px; max-width: 90px;">{{ item.name }}<br>
                <span style="font-size: 12px; ">({{ item.description }})</span></span></td>
              <td style="margin: -5px">{{ item.quantity }}</td>
              <td style="margin: -5px">₹&nbsp;{{ priceWithCommas(item.price) }}</td>
              <td style="margin: -5px">{{ item.discount }} %</td>
              <td style="margin: -5px">₹&nbsp;{{ priceWithCommas(item.netPrice) }}</td>
              <td style="margin: -5px">₹&nbsp;{{ priceWithCommas(item.amount) }}</td>
            </tr>
            <tr>
              <td>-</td>
              <td><b>Transport Charge&nbsp;: </b>₹&nbsp;{{ priceWithCommas(this.form_data.transportCharge) }}</td>
              <td><b>Installation Charge &nbsp;: </b>₹&nbsp;{{ priceWithCommas(this.form_data.installationCharge) }}
              </td>
              <td>-</td>
              <td>=></td>
              <th colspan="2">Total</th>
              <td>₹&nbsp;{{
                  priceWithCommas(convertInt(this.form_data.transportCharge) + convertInt(this.form_data.installationCharge))
                }}
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <th colspan="2">Grand Total</th>
              <td>₹&nbsp;{{ priceWithCommas(this.total) }}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <b>Amount in Words : </b>{{ priceToWords(this.total) }} Rupees Only
        <br>
        <v-divider></v-divider>
        <v-row justify="space-between" class="footer">
          <v-col md="4">
            <div class="tc">
              <h3>Terms & Conditions</h3>
              <p>&emsp; 1. All above prices are inclusive of GST</p>
              <p style="margin-top: -10px;">&emsp; 2. <b>Payment</b> : 100% Advance against delivery</p>
              <p style="margin-top: -10px;">&emsp; 3. <b>Transportation</b> : Extra at actual.</p>
              <p style="margin-top: -10px;">&emsp; 4. <b>Delivery</b> : 1-2 working days from the received of your
                payment</p>
              <p style="margin-top: -10px;">&emsp; 5. <b>Validity</b> : This offer is valid for 30 days</p>
            </div>
          </v-col>
          <v-col md="4">
            <div class="bank_details">
              <h3>Bank Details</h3>
              <div>&emsp;BRIGHT ELECTRICALS,</div>
              <div>&emsp;ICICI BANK</div>
              <div>&emsp;AC NO.- 002705501588</div>
              <div>&emsp;IFSC CODE - ICIC0000027</div>
              <div>&emsp;BRANCH SHARANPUR ROAD, NASHIK.</div>
            </div>
          </v-col>
        </v-row>
        <br>
        <div class="sign_area">
          <span style="font-weight: bold; position: relative">Customer Sign</span>
          <span style="font-weight: bold; position: relative; left: 57%">For, AERA DESIGNER FANS</span>
        </div>
      </div>
    </div>
    <v-snackbar
        v-model="snackbarCheck"
        :timeout="2000"
        color="error"
        text
    >
      {{ errorText }}
    </v-snackbar>

  </v-app>
</template>

<script>
import isJwtTokenExpired, {decode} from "jwt-check-expiry";
import axios from "axios";

export default {
  name: "new_quotation",
  data() {
    return {
      valid: true,
      userName: "",
      token: null,
      form_data: {
        clientName: "",
        clientAddress: "",
        clientPhone: "",
        clientCompany: "",
        clientGSTIN: "",
        careOf: "",
        Id: "",
        data: [{
          imageUrl: "",
          name: "",
          description: "",
          quantity: null,
          price: 0.0,
          discount: null,
          netPrice: 0,
          amount: 0
        }],
        transportCharge: null,
        installationCharge: null,
      },
      itemName: [],
      itemPrice: {},
      clientNames: [],
      clientSearch: null,
      careOfNames: [],
      careOfSearch: null,
      total: 0,
      snackbarCheck: false,
      errorText: null,
      statusCode: null,
      downloading: false,
      rules: {
        clientName: [
          value => !!value || 'Required.',
        ],
        clientAddress: [
          value => !!value || 'Required.',
        ],
        itemName: [
          value => !!value || 'Required.'
        ],
        itemDescription: [
          value => !!value || 'Required.'
        ],
        itemQuantity: [
          value => !!value || 'Required.'
        ]
      },
    }
  },
  watch: {
    form_data: {
      handler(val) {
        this.total = 0;
        let data = val.data;
        data.forEach((item) => {
          this.total += parseInt(item.amount);
        })
        let installationCharge = isNaN(parseInt(this.form_data.installationCharge)) ? 0 : parseInt(this.form_data.installationCharge);
        let transportationCharge = isNaN(parseInt(this.form_data.transportCharge)) ? 0 : parseInt(this.form_data.transportCharge);
        this.total += installationCharge + transportationCharge;
      },
      deep: true
    },
    statusCode: function (val) {
      if (200 < val && val < 500) {
        localStorage.removeItem('Token')
        this.$router.replace("/");
      }
    }
  },
  computed: {
    date: function () {
      const event = new Date(Date.now());
      const options = {year: "numeric", month: "numeric", day: "numeric"};
      return (event.toLocaleDateString('en-IN', options));
    },
  },
  methods: {
    convertInt(val) {
      return parseInt(val);
    },
    imageLinkGen(item) {
      return "https://aera-cdn.amolbohora.com/images/" + item.name + ".jpg?jwt=" + this.token;
    },
    plus() {
      this.form_data.data.push({
        imageUrl: "",
        name: "",
        description: "",
        quantity: null,
        price: 0.0,
        discount: null,
        netPrice: 0,
        amount: 0
      });
    },
    minus(index) {
      this.form_data.data.splice(index, 1);
      if (this.form_data.data.length <= 0) {
        this.form_data.data[0] = {
          imageUrl: "",
          name: "",
          description: "",
          quantity: null,
          price: 0.0,
          discount: null,
          netPrice: 0,
          amount: 0
        }
      }
    },
    priceUpdater(index) {
      let currentData = this.form_data.data[index];
      this.form_data.data[index].netPrice = parseInt(currentData.price *
          parseFloat(1 - (isNaN(parseInt(currentData.discount)) ? 0 : parseInt(currentData.discount) / 100)));
      this.form_data.data[index].amount = parseInt(this.form_data.data[index].netPrice * parseInt(this.form_data.data[index].quantity));
    },
    priceFetcher(index) {
      this.form_data.data[index].price = this.itemPrice[this.form_data.data[index].name];
      this.priceUpdater(index);
    },
    priceWithCommas(val) {
      if (isNaN(val) || val === null)
        return 0;
      val = String(val);
      var lastThree = val.substring(val.length - 3);
      var otherNumbers = val.substring(0, val.length - 3);
      if (otherNumbers !== '')
        lastThree = ',' + lastThree;
      return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    },
    priceToWords(price) {
      var sglDigit = ["Zero", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"],
          dblDigit = ["Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"],
          tensPlace = ["", "Ten", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"],
          handle_tens = function (dgt, prevDgt) {
            return 0 === dgt ? "" : " " + (1 === dgt ? dblDigit[prevDgt] : tensPlace[dgt])
          },
          handle_utlc = function (dgt, nxtDgt, denom) {
            return (0 !== dgt && 1 !== nxtDgt ? " " + sglDigit[dgt] : "") + (0 !== nxtDgt || dgt > 0 ? " " + denom : "")
          };

      let str = "",
          digitIdx = 0,
          digit = 0,
          nxtDigit = 0,
          words = [];
      if (price += "", isNaN(parseInt(price))) str = "";
      else if (parseInt(price) > 0 && price.length <= 10) {
        for (digitIdx = price.length - 1; digitIdx >= 0; digitIdx--) switch (digit = price[digitIdx] - 0, nxtDigit = digitIdx > 0 ? price[digitIdx - 1] - 0 : 0, price.length - digitIdx - 1) {
          case 0:
            words.push(handle_utlc(digit, nxtDigit, ""));
            break;
          case 1:
            words.push(handle_tens(digit, price[digitIdx + 1]));
            break;
          case 2:
            words.push(0 !== digit ? " " + sglDigit[digit] + " Hundred" + (0 !== price[digitIdx + 1] && 0 !== price[digitIdx + 2] ? " and" : "") : "");
            break;
          case 3:
            words.push(handle_utlc(digit, nxtDigit, "Thousand"));
            break;
          case 4:
            words.push(handle_tens(digit, price[digitIdx + 1]));
            break;
          case 5:
            words.push(handle_utlc(digit, nxtDigit, "Lakh"));
            break;
          case 6:
            words.push(handle_tens(digit, price[digitIdx + 1]));
            break;
          case 7:
            words.push(handle_utlc(digit, nxtDigit, "Crore"));
            break;
          case 8:
            words.push(handle_tens(digit, price[digitIdx + 1]));
            break;
          case 9:
            words.push(0 !== digit ? " " + sglDigit[digit] + " Hundred" + (0 !== price[digitIdx + 1] || 0 !== price[digitIdx + 2] ? " and" : " Crore") : "")
        }
        str = words.reverse().join("")
      } else str = "";
      return str
    },
    print() {
      if (this.$refs.form.validate()) {
        document.title = this.form_data.clientName;
        this.form_data["Total"] = this.total;
        for (let i = 0; i < this.form_data.data.length; i++) {
          this.form_data.data[i].price = this.form_data.data[i].price.toString();
        }
        let data = JSON.stringify(this.form_data);
        const options = {
          url: 'https://aera-api.amolbohora.com/create_quotation',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': 'Bearer ' + this.token
          },
          data: data
        };
        let that = this;
        axios(options)
            .then(async response => {
              if (response.status === 200) {
                that.form_data.Id = await response.data['Id'];
                document.title = that.form_data.clientName.toString();
                setTimeout(async function () {
                  await window.print();
                  document.title = "New Quotation";
                }, 100);
              }
            })
            .catch(function (error) {
              that.errorText = error.response.data.details;
              that.snackbarCheck = true;
              that.statusCode = error.status;
              if (error.response.status === 401){
                localStorage.removeItem('Token');
                that.$router.replace("/");
              }
            });
      }
    },
    editDataHandler(rawEditData){
      let editData = JSON.parse(rawEditData);
      this.form_data.Id = editData.Id;
      this.form_data.clientCompany = editData.clientCompany;
      this.form_data.clientName = editData.clientName;
      this.form_data.transportCharge = parseInt(editData.transportCharge);
      this.form_data.clientAddress = editData.clientAddress;
      this.form_data.careOf = editData.careOf;
      this.form_data.clientPhone = editData.clientPhone;
      this.total = parseInt(editData.Total);
      this.form_data.installationCharge = parseInt(editData.installationCharge);
      this.form_data.clientGSTIN = editData.clientGSTIN;
      this.form_data.data.splice(0, 1);
      for (let i = 0; i < editData.data.length; i++){
        this.form_data.data.push({
            imageUrl: "",
            name: editData.data[i].name,
            description: editData.data[i].description,
            quantity: parseInt(editData.data[i].quantity),
            price: parseFloat(editData.data[i].price),
            discount: parseInt(editData.data[i].discount),
            netPrice: parseInt(editData.data[i].netPrice),
            amount: parseInt(editData.data[i].amount)
      })
      }
    }
  },
  beforeCreate() {
    if (localStorage.getItem("Token") === null || isJwtTokenExpired(localStorage.getItem("Token"))) {
      localStorage.removeItem('Token');
      this.$router.replace("/");
    }
  },
  async created() {
    if (this.$route.query.editData !== undefined)
      this.editDataHandler(this.$route.query.editData);
    else if (this.$route.query.reprint !== undefined){
      this.editDataHandler(this.$route.query.reprint);
      this.downloading = true;
      var that = this;
      setTimeout(async function () {
        document.title = that.form_data.clientName.toString();
        await window.print();
        that.downloading = false
        document.title = "New Quotation";
      }, 1000);
    }
    let temp = decode(localStorage.getItem("Token"))['payload'];
    this.userName = temp['name']
    this.token = localStorage.getItem("Token");
    var url = ("https://aera-cdn.amolbohora.com/data/item_data.json?jwt=" + this.token);
    fetch(url)
        .then(res => res.json())
        .then(out => {
              Object.keys(out).forEach(key => {
                this.itemName.push(key);
              })
              this.itemPrice = out;
            }
        )

    url = ("https://aera-cdn.amolbohora.com/data/client_names.json?jwt=" + this.token);
    fetch(url)
        .then(res => res.json())
        .then(out => {
              this.clientNames = out['data']
            }
        )

    url = ("https://aera-cdn.amolbohora.com/data/co_names.json?jwt=" + this.token);
    fetch(url)
        .then(res => res.json())
        .then(out => {
              this.careOfNames = out['data']
            }
        )

  }
}
</script>

<style scoped>


.input_data {
  padding-top: 50px;
  padding-left: 100px;
  padding-right: 100px;
}

.plusButton {
  display: flex;
  justify-content: flex-end;
}

.header {
  margin-top: -10px;
}

.tc {
  font-size: small;
}

.bank_details {
  font-size: small;
}

.print-data {
  display: none;
}


.print-table {
  display: flex;
  justify-content: center;
  margin-top: -20px;
}

table {
  border-collapse: collapse;
}

tr:not(:last-child) > td {
  border: solid thin;
  padding: 5px;
  text-align: center;
}

tr:last-child > td:last-child {
  border: solid thin;
  padding: 5px;
  text-align: center;
}

th {
  border: solid thin;
  padding: 5px;
  text-align: center;
}


@media only screen and (max-width: 1200px) {
  /*Tablets [601px -> 1200px]*/
}

@media only screen and (max-width: 600px) {
  .input_data {
    padding-right: 50px;
    padding-left: 50px;
  }
}

@media only screen and (max-width: 425px) {
  .input_data {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media print {
  .input_data {
    display: none;
  }

  @page {
    width: 21cm;
    height: 29.7cm;
    margin: 5px;
  }


  .print-data {
    display: block;
  }
  .bank_details, .tc, .footer {
    page-break-inside: avoid;
  }
}

</style>