<template>
  <v-app>
    <v-app-bar app>
      Welcome {{this.name}}
        <v-img src="../assets/logo.webp" alt="Aera Fans Logo" class="logo" />
    </v-app-bar>
    <v-container class="menu">
      <v-row justify="space-around">
        <div v-for="(item, index) in paths" :key="index">
          <v-btn
              elevation="24"
              x-large
              color="#F04B2F"
              min-width="45vh"
              class="choiceButton"
              outlined
              v-on:click="navigate(index)"
          >{{item}}</v-btn>
        </div>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import isJwtTokenExpired, { decode } from 'jwt-check-expiry';
import logoutUser from '../scripts/logout';

export default {
  name: "dashboard",
  data(){
    return{
      paths: ["Create a new quotation", "See Past Quotation", "Add New Items", "Confirm Quotation", "Modify Item Price", "List Items", "Logout"],
      name: null,
    }
  },
  methods:{
    navigate(index){
      if (index === 0)
        this.$router.push("/create");
      else if (index === 1)
        this.$router.push("/search");
      else if (index === 2)
        this.$router.push("/add");
      else if (index === 3)
        this.$router.push("/confirm");
      else if (index === 4)
        this.$router.push("/modify");
      else if (index === 5)
        this.$router.push("/list")
      else
        logoutUser();
    }
  },
  beforeCreate() {
    if (localStorage.getItem("Token") === null || isJwtTokenExpired(localStorage.getItem("Token"))) {
      localStorage.removeItem('Token');
      this.$router.replace("/");
    }
  },
  created() {
    let temp = decode(localStorage.getItem("Token"))['payload'];
    this.name = temp['name']
  }
}
</script>

<style scoped>

.choiceButton{
  margin-top: 5vh;
}

.menu{
  padding: 50px;
}

.logo{
  max-width: 250px;
  position: absolute;
  right: -50px;
}

</style>